import styled from 'styled-components'
import {
  ROUTE_PRESS_RELEASE_ARTICLE_WITH_LANG,
  ROUTE_PRESS_RELEASE_WITH_LANG,
} from '../../common/utils/constants'
import media from '../../common/utils/media'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Header from '../organisms/Header'
import LanguageToggle from '../molecules/LanguageToggle'

const PressReleasePage = () => {
  const params = useParams() as any
  const history = useHistory()

  const goToPressReleasePage = (source: string) => {
    history.push(
      ROUTE_PRESS_RELEASE_ARTICLE_WITH_LANG.replace(':article', source).replace(
        ':lang',
        selectedLang
      )
    )
  }

  const [pressReleases, setPressReleases] = useState([])
  const [loading, setLoading] = useState(true)

  const [selectedLang, setSelectedLang] = useState(params.lang || '')
  const [langs, setLangs] = useState(['ja']) as any

  const getData = () => {
    // prevent browser from caching the file by appending random parameter.
    // source: https://stackoverflow.com/a/15041641
    fetch(
      `../../press-releases/press-releases.json?nocache=${new Date().getTime()}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    )
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        const allLangs = Object.keys(json['press-releases'])
        let tmpSelectedLang = selectedLang
        if (!tmpSelectedLang || !allLangs.includes(tmpSelectedLang)) {
          tmpSelectedLang = allLangs[0]
          setSelectedLang(tmpSelectedLang)
        }
        setLangs(allLangs)
        setPressReleases(json['press-releases'][tmpSelectedLang])
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Header />
      <StyledPressRelease>
        {!loading && (
          <>
            <div className="subtitle-container">
              <h2>Press Release</h2>
            </div>
            <LanguageToggle
              routeWithLang={ROUTE_PRESS_RELEASE_WITH_LANG}
              langs={langs}
              selectedLang={selectedLang}
            />
            <br />
            <div className="press-releases">
              {pressReleases && pressReleases.length > 0 ? (
                pressReleases.map((pressRelease: any) => (
                  <li
                    key={pressRelease.title}
                    onClick={() => {
                      goToPressReleasePage(pressRelease.source)
                    }}
                  >
                    {pressRelease.date.split(' ')[0]}
                    {': '}
                    {pressRelease.title}
                  </li>
                ))
              ) : (
                <p>Please come back later.</p>
              )}
            </div>
          </>
        )}
      </StyledPressRelease>
    </>
  )
}

export default PressReleasePage

export const StyledPressRelease = styled.div`
  padding: 80px 100px;
  text-align: left;
  width: 50%;

  ${media.pcNormal`
      width: 50%;
  `}

  ${media.tablet`
      padding: 80px 50px;
      width: 100%;
  `}

  div.subtitle-container {
    width: fit-content;
  }
  h2 {
    width: 100%;
    text-align: left;
    margin-bottom: 0;
    font-family: montserrat;
  }

  div.press-releases {
    & {
      cursor: pointer;
      margin-bottom: 10px;
      width: fit-content;
      color: white;

      li:hover {
        text-decoration: underline;
      }

      li {
        padding: 5px;
      }
    }
  }
`
