import styled from 'styled-components'
import { ROUTE_PRESS_RELEASE_WITH_LANG } from '../../common/utils/constants'
import media from '../../common/utils/media'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Header from '../organisms/Header'
import BackButton from '../atoms/BackButton'

const PressReleaseArticlePage = () => {
  const parse = require('html-react-parser')

  const history = useHistory()
  const params = useParams() as any
  const [selectedLang, setSelectedLang] = useState(params.lang || '')
  const [articleSource] = useState(params.article || '')

  const [article, setArticle] = useState(null) as any
  const [articleTitle, setArticleTitle] = useState('')
  const [articleDate, setArticleDate] = useState('')
  const [loading, setLoading] = useState(true)

  const parseHTML = (htmlString: string) => {
    try {
      return parse(htmlString)
    } catch (err) {
      return ''
    }
  }

  const getData = () => {
    // prevent browser from caching the file by appending random parameter.
    // source: https://stackoverflow.com/a/15041641
    fetch(
      `../../press-releases/press-releases.json?nocache=${new Date().getTime()}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    )
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        const allLangs = Object.keys(json['press-releases'])
        let tmpSelectedLang = selectedLang
        if (!tmpSelectedLang || !allLangs.includes(tmpSelectedLang)) {
          tmpSelectedLang = allLangs[0]
          setSelectedLang(tmpSelectedLang)
        }
        const pressReleases = json['press-releases'][tmpSelectedLang]

        for (let key in pressReleases) {
          const p = pressReleases[key]
          if (p.source === articleSource) {
            setArticleTitle(p.title)
            setArticleDate(p.date)
          }
        }
        setArticle('')
        // check if article exists first before fetching the content
        fetch(
          `../../press-releases/articles/${tmpSelectedLang}/${articleSource}.html?nocache=${new Date().getTime()}`,
          { method: 'HEAD' }
        )
          .then((response) => {
            if (response.ok) {
              // fetch content if article exists
              fetch(
                `../../press-releases/articles/${tmpSelectedLang}/${articleSource}.html?nocache=${new Date().getTime()}`
              )
                .then((response) => {
                  return response.text()
                })
                .then((html) => {
                  setArticle(parseHTML(html))
                  setLoading(false)
                })
            } else {
              throw response
            }
          })
          .catch((err) => {
            setArticle('')
            setLoading(false)
            history.push(
              ROUTE_PRESS_RELEASE_WITH_LANG.replace(':lang', tmpSelectedLang)
            )
          })
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getData()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Header />
      <StyledPressReleaseArticle>
        <BackButton
          previousPage={ROUTE_PRESS_RELEASE_WITH_LANG.replace(
            ':lang',
            selectedLang
          )}
        />
        {articleTitle && article && (
          <div className="article-header">
            <h2>{articleTitle}</h2>
            <p>{articleDate}</p>
          </div>
        )}
        <br />
        <br />
        {article && <div>{article}</div>}
        {!loading && !article && <div>Article not found</div>}
      </StyledPressReleaseArticle>
    </>
  )
}

export default PressReleaseArticlePage

export const StyledPressReleaseArticle = styled.div`
  padding: 80px 100px;
  text-align: left;
  width: 55%;

  ${media.pcNormal`
      width: 55%;
    `}

  ${media.tablet`
      width: 80%;
    `} 

  ${media.tablet`
    padding: 80px 50px;
  `}

  h2 {
    width: 100%;
    text-align: left;
    margin-bottom: 0;
    font-family: montserrat;
  }

  p {
    text-align: left;
    margin: 20px 0;
  }

  .bold {
    font-weight: bold;
  }

  a {
    cursor: pointer;
    margin-bottom: 10px;
    text-decoration: underline;

    :hover {
      color: lightgrey;
    }
  }

  div.sub {
    padding: 30px;
    border: 1px solid white;
    border-radius: 15px;

    p {
      display: inline;
    }
  }

  table {
    border-collapse: collapse;

    td {
      border: 1px solid white;
      padding: 5px 10px;
    }
  }

  div.article-header {
    p {
      margin: 0;
    }
  }
`
